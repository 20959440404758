<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card>
          <b-card-body class="p-0">
      <b-row>
        <b-col cols="6" md="6">
          <b-form-group label="Date Range" label-for="Date Range">

            <flat-pickr
              v-model="rangeDate"
              @input="onChangeDate(rangeDate)"
              class="form-control"
              :config="{ mode: 'range' }"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6" md="6">
          <b-form-group label="Action" label-for="Action">
          <b-button
        variant="primary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        @click="updateValues"
      >
        Submit
      </b-button>
      </b-form-group>
          
        </b-col>
      </b-row>
      <!-- Action Buttons -->
            <b-table
              striped
              hover
              responsive
              class="position-relative"
              :per-page="perPage"
              :current-page="currentPage"
              :items="history_list"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            >
              <template #cell(action)="data">
                <b-dropdown
                  variant="link"
                  no-caret
                  :right="$store.state.appConfig.isRTL"
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item
                    v-b-modal.modal-center
                    :to="{
                      name: 'clock-history-edit',
                      params: { id: data.item.id },
                    }"
                  >
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">Edit</span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
      
      <script>
import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BFormCheckboxGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormTimepicker,
  BFormDatepicker,
} from "bootstrap-vue";
import { required, min, confirmed } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { serverUri } from "@/config";
import router from "@/router";
import { getUserData } from "@/auth/utils";
import flatPickr from "vue-flatpickr-component";
import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BFormCheckbox,
    BFormCheckboxGroup,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    BInputGroup,
    BInputGroupAppend,
    BFormTimepicker,
    BFormDatepicker,
    flatPickr,
    DateRangePicker,
  },
  data() {
    return {
      dateRange: "",
      rangeDate: null,
      date: "",
      out_date: "",
      userId: getUserData().id,
      companyId: getUserData().company_id,
      clockId: router.currentRoute.params.id,
      clock_in: "",
      clock_out: "",
      dateRange: {
        startDate: new Date().toDateString(),
        endDate: new Date().toDateString(),
      },
      startDate: null,
            endDate: null,
      perPage: 25,
      pageOptions: [5, 10, 15, 25],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: " ",
      history_list: [],
      filterOn: [],
      fields: [
        {
          key: "from_date",
          label: "From Date",
          sortable: false,
          formatter: (value) => {
            return moment(value).format("MM/DD/YYYY");
          },
        },
        {
          key: "to_date",
          label: "To Date",
          sortable: false,
          formatter: (value) => {
            return moment(value).format("MM/DD/YYYY");
          },
        },
        {
          key: "notes",
          label: "Notes",
          sortable: false,
          formatter: (value) => {
            return value;
          },
        },
        {
          key: "status",
          label: "Status",
          sortable: false,
          formatter: (value) => {
            if (value == 1) {
              return "Approved";
            } else if (value == 2) {
              return "Denied";
            } else {
              return "Pending";
            }
          },
        },
      ],
    };
  },
  mounted() {
    this.getRequestOffHistory();
  },
  computed: {},
  props: {},
  methods: {
    showToast(variant, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: "BellIcon",
          text: text,
          variant,
        },
      });
    },

    onChangeDate(date) {
      if (date.split("to")) {
        this.startDate = date.split("to")[0];
        this.endDate = date.split("to")[1];
      } else {
        this.startDate = date;
        this.endDate = date;
      }

      // this.onLoad();
    },

    updateValues() {

      if(this.startDate == null || this.startDate == '')
      {
        this.showToast("danger", "Warning", 'Please select date');
        return false;
      } 


      this.$http
        .post(`${serverUri}/driver/add-driver-request-off`, {
          company_id:this.companyId,
          driver_id: this.userId,
          start_date: this.startDate,
          end_date: this.endDate == '' ? this.startDate : this.endDate,
        })
        .then((response) => {
          this.data = response.data;
          if (response.data.status == true) {
            this.showToast("success", "Success", response.data.message);
            this.getRequestOffHistory();
          } else {
            this.showToast("danger", "Warning", response.data.message);
          }
        });
    },
    onUpdate() {},

    getRequestOffHistory() {
      this.$http
        .post(`${serverUri}/driver/request-off-history`, {
          driver_id: this.userId,
          company_id:this.companyId
        })
        .then((response) => {
          this.data = response.data;
          if (response.data.status == true) {
            this.history_list = response.data.data;
          } else {
            this.history_list = [];
          }
        });
    },

    showToast(variant, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: "BellIcon",
          text: text,
          variant,
        },
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
  created() {},
};
</script>
      
      <style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.edit-header {
  display: flex;
  align-items: center;
}
</style><style>
.permission {
  margin: 10px 0;
}

.permission div {
  margin: 2px 0;
}
</style>
      
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>